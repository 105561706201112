(function($) {
    $(document).ready(function () {

        var loginBtn = document.querySelector('span#ratsula-header-login');
        if (loginBtn) {
            loginBtn.addEventListener('click', function () {
                var loginForm = document.querySelector('#ratsula-login-form');
                if (loginForm) {
                    loginForm.style.display = 'flex';
                }
            });
        }
        var loginBtnMobile = document.querySelector('span#ratsula-header-login-mobile');
        if (loginBtnMobile) {
            loginBtnMobile.addEventListener('click', function () {
                var loginForm = document.querySelector('#ratsula-login-form');
                if (loginForm) {
                    loginForm.style.display = 'flex';
                }
            });
        }
        var closeLoginForm = document.querySelector('img#ratsula-login-form-close-btn');
        if (closeLoginForm) {
            closeLoginForm.addEventListener('click', function () {
                var loginForm = document.querySelector('#ratsula-login-form');
                if (loginForm) {
                    loginForm.style.display = 'none';
                }
            });
        }

        var hidebtn = document.querySelector('#ratsula-mobile-close-aup');
        if (hidebtn) {
            hidebtn.addEventListener('click', function () {
                var tgl = document.querySelector('.aukioloaika-now.toggle-label');
                if (tgl) {
                    tgl.click();
                }
                
            });
        }
        var hidebtnG = document.querySelector('#ratsula-mobile-close-gomee');
        if (hidebtnG) {
            hidebtnG.addEventListener('click', function () {
                console.log('click')
                var togglelbl = document.querySelector('.gomee-title.show-on-medium .toggle-label');
                if (togglelbl) {
                    console.log('found')
                    togglelbl.click();
                }

            });
        }
        
        var aspaMenu = document.querySelector('#page-menu-aspa');
        if (aspaMenu) {
            const aspaLink = document.createElement("div");
            aspaLink.classList.add('aspa-btn');
            aspaLink.innerText = "Asiakaspalvelu";
            const mainSection = document.querySelector('section#main');
            mainSection.appendChild(aspaLink);
    
            var abtn = document.querySelector('.aspa-btn');
            if (abtn) {
                abtn.addEventListener('click', function () {
                    var aspamenu = document.querySelector('#page-menu-aspa');
                    if (aspamenu) {
                        aspamenu.classList.toggle('hidden');
                    }
                });
            }
            var abtnClose = document.querySelector('#page-menu-aspa-close-button');
            if (abtnClose) {
                abtnClose.addEventListener('click', function () {
                    var aspamenu = document.querySelector('#page-menu-aspa');
                    if (aspamenu) {
                        aspamenu.classList.toggle('hidden');
                    }
                });
            }
            document.addEventListener('click', function (event) {
                var menu = document.querySelector('#page-menu-aspa-inner');
                if (!menu.contains(event.target) && !event.target.classList.contains('aspa-btn')) {
                    document.querySelector('#page-menu-aspa').classList.add('hidden');
                }
            });
        }

        const href = window.location.href;

        url = new URL(href);
        if (url.searchParams.has('maksutavat')) {
            const otop = document.querySelector('#maksutavat').offsetTop;
            const hder = document.querySelector('#page-header').offsetHeight;
            window.scrollTo(0, (otop - hder));
        }

        if (href.indexOf("tuotteet")) {
            
            window.onscroll = function () {
                const height = window.innerHeight || document.documentElement.clientHeight;
                if (document.body.scrollTop > (height * 0.5) || document.documentElement.scrollTop > (height * 0.5)) {
                    document.querySelector('.scroll-back-to-top-btn').style.display = "block";
                    document.querySelector('.aspa-btn').classList.add('moved');
                } else {
                    document.querySelector('.scroll-back-to-top-btn').style.display = "none";
                    document.querySelector('.aspa-btn').classList.remove('moved');
                }
            };

            const scrollTop = document.createElement("div");
            scrollTop.classList.add('scroll-back-to-top-btn');
            const mainSection = document.querySelector('section#main');
            mainSection.appendChild(scrollTop);

            var st = document.querySelector('.scroll-back-to-top-btn');
            if (st) {
                st.addEventListener('click', function () {
                    window.scrollTo(0, 0);
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                });
            }
        }

        if (href.indexOf("minun-ratsula") > -1 && href.indexOf("lahjakortin-tilaus") > -1) {
            
            // const table = document.querySelectorAll("table.tabledrag-processed");
            // window.showAddGiftCardLink = {
            //     sum: false,
            //     name: false,
            //     ship: false,
            // };
            // if (table.length > 0) {
            //     const link = document.querySelector("input.field-add-more-submit");

            //     const inputs = table[0].querySelectorAll("input");
            //     if (inputs.length > 0) {
            //         const itm = inputs[0];
            //         itm.addEventListener("keyup", function () {
            //             if (itm.value && itm.value.length > 0) {
            //                 window.showAddGiftCardLink.sum = true;
            //                 if (window.showAddGiftCardLink.ship === true && window.showAddGiftCardLink.name === true && window.showAddGiftCardLink.sum === true) {
            //                     if (link) {
            //                         link.style.display = "block";
            //                     }
            //                 }
            //             } else {
            //                 window.showAddGiftCardLink.sum = false;
            //                 if (link) {
            //                     link.style.display = "none";
            //                 }
            //             }
            //         });
            //         const itmm = inputs[1];
            //         itmm.addEventListener("keyup", function () {
            //             if (itmm.value && itmm.value.length > 0) {
            //                 window.showAddGiftCardLink.name = true;
            //                 if (window.showAddGiftCardLink.ship === true && window.showAddGiftCardLink.name === true && window.showAddGiftCardLink.sum === true) {
            //                     if (link) {
            //                         link.style.display = "block";
            //                     }
            //                 }
            //             } else {
            //                 window.showAddGiftCardLink.name = false;
            //                 if (link) {
            //                     link.style.display = "none";
            //                 }
            //             }
            //         });
            //     }

            //     const radios = table[0].querySelector(".form-radios");
            //     if (radios) {
            //         const rinputs = radios.querySelectorAll('input[type="radio"]');
            //         if (rinputs.length > 0) {
            //             for (var indx = 0; indx < rinputs.length; indx++) {
            //                 const element = rinputs[indx];
            //                 element.addEventListener("click", function () {
            //                     window.showAddGiftCardLink.ship = true;
            //                     if (window.showAddGiftCardLink.ship === true && window.showAddGiftCardLink.name === true && window.showAddGiftCardLink.sum === true) {
            //                         if (link) {
            //                             link.style.display = "block";
            //                         }
            //                     }
            //                 });
            //             }
            //         }
            //     }
            // }
        }

        if (href.indexOf("minun-ratsula") > -1 && href.indexOf("omat-tiedot") > -1) {
            document.querySelectorAll('.ratsula-tiedot-tab').forEach(function (element) {
                element.addEventListener('click', function () {
                    var _t = $(this);
                    var tab = _t.attr('data-tabi');

                    $('.ratsula-tiedot-tab').removeClass('selected');
                    _t.addClass('selected');

                    if (tab == 'perustiedot') { 
                        $('.ratsula-omat-nimi-info').show();
                        $('#profile-main-edit').show();
                        $('#salasana-container-title').show();
                        $('#salasana-container').show();
                        $('#edit-salasana-paivitys').show();
                        $('#profile-kiinnostukset-edit').show();

                        $('#profile-peruskokotiedot-edit').hide();
                        $('#profile-sizes-edit').hide();
                        $('.view-kiinnostavat-br-ndit').hide();
                    } else if (tab == 'brandit') {
                        $('.ratsula-omat-nimi-info').hide();
                        $('#profile-main-edit').hide();
                        $('#salasana-container-title').hide();
                        $('#salasana-container').hide();
                        $('#edit-salasana-paivitys').hide();
                        $('#profile-kiinnostukset-edit').hide();

                        $('#profile-peruskokotiedot-edit').hide();
                        $('#profile-sizes-edit').hide();
                        $('.view-kiinnostavat-br-ndit').show();
                    } else {
                        $('.ratsula-omat-nimi-info').hide();
                        $('#profile-main-edit').hide();
                        $('#salasana-container-title').hide();
                        $('#salasana-container').hide();
                        $('#edit-salasana-paivitys').hide();
                        $('#profile-kiinnostukset-edit').hide();

                        $('#profile-peruskokotiedot-edit').show();
                        $('#profile-sizes-edit').show();
                        $('.view-kiinnostavat-br-ndit').hide();
                    }

                    // $('.ratsula-tiedot-tab-content').removeClass('active');
                    // $('.ratsula-tiedot-tab-content[data-tab="' + tab + '"]').addClass('active');
                });
            });
        }

        if (href.indexOf("minun-ratsula") > -1 && href.indexOf("etukupongit") > -1) {
            document.querySelector('.code-area').addEventListener('click', function () {
                $('.code-area .code').select();
                document.execCommand('copy');
            });
            document.querySelector('.code').addEventListener('click', function () {
                // copy text to clipboard from inner element '.code'
                $('.code-area .code').select();
                document.execCommand('copy');
            });
            document.querySelector('#copy-code').addEventListener('click', function () {
                console.log('click 3')
                // copy text to clipboard from inner element '.code'
                var asd = $('.code').first();
                console.log(asd);
                asd.select();
                document.execCommand('copy');
            });
        }

        $('#page-menu .menu-name-main-menu li.expanded').each(function () {
            var _t = $(this);

            var link = _t.find('> a');

            if (link.length == 1) {
                var row = $('<span class="menu-item-row"></span>');
                var collapser = $('<a class="menu-item-collapser"></a>');

                row.append(link);
                row.append(collapser);
                _t.prepend(row);
            }

        });

        $('.ratsula-shop-category-menu, .ratsula-shop-category-page-menu, #page-menu .menu-name-main-menu').each(function () {
            var _t = $(this)

            _t.find('.menu-item-collapser').click(function (e) {
                e.preventDefault();
                var _ti = $(this);

                _ti.parent().parent().toggleClass('opened');
            });
        });

        var categoryMenu = $('.ratsula-shop-category-menu');
        if (categoryMenu.length > 0) {
            var margin = 16;
            var isFixed = false;
            var menu = categoryMenu;
            var header = $('#page-header').get(0);
            var contentLeft = $('#content-left');
            var contentLeftelement = contentLeft.get(0);
            var footer = $('footer').get(0);
            var footerStartsAt = window.scrollY + footer.getBoundingClientRect().top;
            var menuWidth = contentLeft.width();
            menu.css('width', menuWidth);

            window.addEventListener('resize', function () {
                menu.css('width', contentLeft.width());
            });

            var headerHeight = header.getBoundingClientRect().height + header.getBoundingClientRect().top;
            var scrollStartsAt = window.scrollY + contentLeftelement.getBoundingClientRect().top - headerHeight - margin;

            var maxHeight = window.innerHeight - margin - headerHeight;
            menu.css('maxHeight', maxHeight + 'px');

            window.addEventListener('scroll', function() {
                footerStartsAt = window.scrollY + footer.getBoundingClientRect().top;

                if (window.innerHeight + window.scrollY > footerStartsAt) {
                    var maxHeight = footerStartsAt - headerHeight - window.scrollY - (margin * 2);
                    menu.css('maxHeight', maxHeight + 'px');
                } else {
                    headerHeight = header.getBoundingClientRect().height + header.getBoundingClientRect().top;
                    scrollStartsAt = window.scrollY + contentLeftelement.getBoundingClientRect().top - headerHeight - margin;

                    var maxHeight = window.innerHeight - margin - headerHeight;
                    menu.css('maxHeight', maxHeight + 'px');
                }

                if (window.scrollY > scrollStartsAt) {
                    if (!isFixed) {
                        menu.css('position', 'fixed');
                        menu.css('top', (headerHeight + margin) + 'px');
                        isFixed = true;
                    }
                } else {
                    if (isFixed) {
                        menu.css('position', 'static');
                        isFixed = false;
                    }
                }
            });
        }

        $('.logged-in .login-nav > a').click(function (e) {
            e.preventDefault();
            $(this).parent().toggleClass('opened');
        });

        var kerrosButtons = $('#front-kerrokset');
        var kerrokset = $('.view-kerrokset .kerros');
        kerrosButtons.html('');
        var isFirst = true;

        kerrokset.each(function () {
            var container = $(this);
            var vgr = container.find('.view-group-rows');

            var curPos = 0;
            var vgrCount = vgr.children().length;
            var maxPos = (vgrCount - 1) * 100;
            vgr.css('width', (maxPos + 100) + '%');

            vgr.find('.views-row').each(function () {
                $(this).css('width', (100 / vgrCount) + '%');
            })

            var left = container.find('.left');
            var right = container.find('.right');

            if (curPos == maxPos) {
                left.addClass('visibility-hidden');
                right.addClass('visibility-hidden');
            }

            left.click(function (e) {
                e.preventDefault();

                if (curPos > 0) {
                    curPos -= 100;
                } else {
                    curPos = maxPos;
                }

                vgr.css('transform', 'translate(-' + (curPos / vgrCount) + '%)');
            })

            right.click(function (e) {
                e.preventDefault();
                curPos += 100;

                if (curPos > maxPos) {
                    curPos = 0;
                }

                vgr.css('transform', 'translate(-' + (curPos / vgrCount) + '%)');
            });

            var kerrosName = container.find('.kerros-name');

            var kerrosButton = $('<a class="kerros-button"></a>');
            kerrosButton.text(kerrosName.html());

            kerrosButton.click(function (e) {
                kerrokset.hide('fast');
                container.show('fast');

                kerrosButtons.find('a').removeClass('active');
                $(this).addClass('active');
            });

            if (isFirst) {
                isFirst = false;
                kerrosButton.addClass('active');
            }

            kerrosButtons.append(kerrosButton);
            kerrosName.remove();
        });

        if (Drupal.settings.ratsula_shop && Drupal.settings.ratsula_shop.additionalCartText) {
            var element = $('.group-right .field-name-field-tuote');

            if (element.length > 0) {
                $(Drupal.settings.ratsula_shop.additionalCartText).insertAfter(element);
            }
        }
    });
})(jQuery);

Drupal.behaviors.ratsula_shop_sizes_of_color = {
    attach: function (context, settings) {
        var $ = jQuery;

        $('.view-id-tuotelistaus .views-row').each(function () {
            var _t = $(this);
            var media = _t.find('.field-name-field-tuotteen-kuva');
            var allSizes = [];
            var insideRow = false;
            var allSizeContainer = $('<div class="size-list-container"></div>');

            _t.find('.field-name-all-colors-of-product .product-color').each(function() {
                var _p = $(this);
                var _c = $('<div class="size-list-container"></div>');
                var sizes = JSON.parse(_p.attr('data-sizes'));
                var sizeNames = [];

                for (var sizeKey in sizes) {
                    if (sizeNames.indexOf(sizes[sizeKey].name) !== -1) {
                        continue;
                    }

                    var elm = $('<span class="size-item"></span>').text(sizes[sizeKey].name);
                    var allSize = allSizes.filter(function (s) {
                        return s.name == sizes[sizeKey].name;
                    })[0];

                    if (!allSize) {
                        allSize = {
                            name: sizes[sizeKey].name,
                            element: $('<span class="size-item not-in-stock"></span>').text(sizes[sizeKey].name),
                        };

                        allSizes.push(allSize);
                    }

                    if (!sizes[sizeKey].in_stock) {
                        elm.addClass('not-in-stock');
                    } else {
                        allSize.element.removeClass('not-in-stock');
                    }

                    _c.append(elm);
                    sizeNames.push(sizes[sizeKey].name);
                }

                _p.mouseover(function () {
                    if (insideRow) {
                        allSizeContainer.remove();
                    }

                    media.append(_c);
                }).mouseout(function () {
                    _c.remove();

                    if (insideRow) {
                        media.append(allSizeContainer);
                    }

                });
            });

            for (var as in allSizes) {
                allSizeContainer.append(allSizes[as].element);
            }

            _t.mouseover(function () {
                media.append(allSizeContainer);
                insideRow = true;
            }).mouseout(function () {
                allSizeContainer.remove();
                insideRow = false;
            });
        });
    }
}

Drupal.behaviors.ratsula_shop_product_media = {
    elm: null,
    attach: function (context, settings) {}
};

Drupal.behaviors.ratsula_on_sale = {
    elm: null,
    attach: function (context, settings) {
        // if (context !== document) {
        //     return;
        // }

        if (this.elm) {
            this.elm.remove();
            this.elm = null;
        }

        var onSalePercent = settings.ratsula_on_sale ? settings.ratsula_on_sale.sale_percent : null;
        var isMrDiscount = settings.ratsula_on_sale ? settings.ratsula_on_sale.mr_discount : false;
        var isDoubleBonus = settings.ratsula_on_sale ? settings.ratsula_on_sale.double_bonus : false;
        var seasonCampaign = settings.ratsula_on_sale ? settings.ratsula_on_sale.season_campaign : false;
        var isOutlet = settings.ratsula_on_sale ? settings.ratsula_on_sale.outlet : false;

        if (isOutlet) {
            // do nothing
        } else if (isMrDiscount) {
            var e = jQuery('.group-left');

            if (e.length > 0) {
                var elm = jQuery('<div class="sale-container"><div class="sale"><img src="/sites/all/modules/custom/ratsula_shop/img/mr-tag.png" alt="Minun Ratsula -hinta" /></div></div>');

                if (seasonCampaign) {
                    elm.addClass('season-campaign');
                }

                this.elm = elm;
                e.prepend(elm);
            }
        } else if (onSalePercent) {
            var e = jQuery('.group-left');

            if (e.length > 0) {
                var elm = jQuery('<div class="sale-container"><div class="sale"><div class="sale-text link-like-button">-' + onSalePercent + '%</div></div></div>');

                if (seasonCampaign) {
                    elm.addClass('season-campaign');
                }

                this.elm = elm;
                e.prepend(elm);
            }
        } else if (isDoubleBonus) {
            var e = jQuery('.group-left');

            if (e.length > 0) {
                var elm = jQuery('<div class="sale-container"><div class="sale"><div class="sale-text link-like-button" style="font-size: 0.7em;">Tuplahyvitys</div></div></div>');

                if (seasonCampaign) {
                    elm.addClass('season-campaign');
                }

                this.elm = elm;
                e.prepend(elm);
            }
        }
    }
};

Drupal.behaviors.ratsula_shop_list_images = {
    attach: function (context, settings) {
        var $ = jQuery;
        var productPageContainer = $('body.node-type-tuote .node-tuote .commerce-product-field-field-media');
        var closeButton = null;

        document.addEventListener('keydown', function (e) {
            if (e.key == 'Escape' && closeButton) {
                closeButton.click();
            }
        });

        document.addEventListener('clear-pig', function (e) {
            var lg = document.querySelector('.product-images-bigslider');
            lg.classList.remove('show-slider');
            lg.innerHTML = '';
        });


        var bodyy = $('body');
        $('.product-images-bigslider').remove();
        var clickSlider = $('<div class="product-images-bigslider"></div>');
        bodyy.prepend(clickSlider);


        if (productPageContainer.length > 0) {
            var mainImage = $('.product-main-image');
            
            if (mainImage.length == 0) {
                mainImage = $('<div class="product-main-image"></div>');
                productPageContainer.prepend(mainImage);
            }

            var vuemainImage = $('.ratsula-shop-product-images');
            if (vuemainImage.length == 0) {
                vuemainImage = $('<ratsula-shop-product-images></ratsula-shop-product-images>');
                productPageContainer.prepend(vuemainImage);
            }

            var setImageToMain = function (image) {
                var a = mainImage.find('a');
                var img = mainImage.find('img');

                if (a.length === 0) {
                    a = $('<a></a>');
                    img = $('<img />');

                    a.append(img);

                    mainImage.append(a);
                }

                img.attr('src', image.attr('href'));
                img.attr('alt', image.find('img').attr('alt'));

                a.attr('href', image.attr('data-full-image'));
            }

            var imgs = productPageContainer.find('.product-images > a');

            if (imgs.length > 0) {
                var img = $(imgs[0]);

                img.addClass('active');
                setImageToMain(img);
            }

            var img = $('<img />');

            const gotoLeftImg = function (curr) {
                for (var i = 0; i < imgs.length; i++) {
                    const iimg = $(imgs[i]);

                    if (iimg.attr('data-full-image') == curr.get(0).href) {
                        const k = i == 0 ? imgs.length - 1 : i - 1;
                        const aimg = $(imgs[k]);

                        curr.attr('href', aimg.attr('data-full-image'));
                        img.attr('src', aimg.attr('data-full-image'));

                        setImageToMain(aimg);
                        return;
                    }
                }
            };

            const gotoRightImg = function (curr) {
                for (var i = 0; i < imgs.length; i++) {
                    const iimg = $(imgs[i]);

                    if (iimg.attr('data-full-image') == curr.get(0).href) {
                        const k = i + 1 >= imgs.length ? 0 : i + 1;
                        const aimg = $(imgs[k]);

                        curr.attr('href', aimg.attr('data-full-image'));
                        img.attr('src', aimg.attr('data-full-image'));

                        setImageToMain(aimg);
                        return;
                    }
                }
            };

            mainImage.on('click', 'a', function (e) {
                e.preventDefault();
                var _t = $(this);
                
                var arrowListener = function (e) {
                    if (e.code == 'ArrowLeft') {
                        gotoLeftImg(_t);
                    } else if (e.code == 'ArrowRight') {
                        gotoRightImg(_t);
                    }
                };

                document.addEventListener('keydown', arrowListener);

                var body = $(document.body);


                var container = $('<div class="image-preview showing"></div>');
                var close = $('<a class="close"><img src="/sites/all/themes/ratsula/img/X.svg" /></a>');
                closeButton = close;
                var imgContainer = $('<div class="image-container"></div>');

                container.click(function (e) {
                    e.stopPropagation();

                    if (e.target == container.get(0)) {
                        closeButton.click();
                    }
                });

                close.click(function () {
                    closeButton = null;
                    container.removeClass('showing').addClass('removing');
                    body.removeClass('preview-mode');
                    document.removeEventListener('keydown', arrowListener);

                    setTimeout(function () {
                        container.remove();
                    }, 750);
                });

                img.attr('src', _t.attr('href'));

                const leftBtn = document.createElement('a');
                leftBtn.classList.add('arrowbtn', 'left');
                leftBtn.addEventListener('click', function () { gotoLeftImg(_t); });
                
                const rightBtn = document.createElement('a');
                rightBtn.classList.add('arrowbtn', 'right');
                rightBtn.addEventListener('click', function () { gotoRightImg(_t); });
                
                container.append(leftBtn);
                container.append(rightBtn);

                imgContainer.append(img);
                container.append(close);
                container.append(imgContainer);
                body.addClass('preview-mode');
                body.append(container);
            });

            productPageContainer.on('click', '.product-images a', function (e) {
                e.preventDefault();

                var _t = $(this);

                if (!_t.hasClass('active')) {
                    productPageContainer.find('.product-images a.active').removeClass('active');
                    _t.addClass('active');
    
                    setImageToMain(_t);
                }
            });

        }
    }
};

// Drupal.behaviors.ratsula_shop_filters = {
//     inited: false,
//     openedFilter: null,
//     changed: false,
//     attach: function (context, settings) {
//         Drupal.behaviors.ratsula_shop_filters.changed = false;

//         jQuery(context).find('#views-exposed-form-tuotelistaus-block-1').once('ratsula-shop-filters', function () {
//             const onChangeHandler = function () {
//                 const $ = jQuery;
//                 if ('URLSearchParams' in window) {
//                     const filters = [];
//                     const filterNames = [];

//                     $('#views-exposed-form-tuotelistaus-block-1').find('select, input[type="text"]').each(function () {
//                         const _t = $(this);
//                         const isArray = _t.attr('name').indexOf('[]') >= 0;

//                         filters.push({
//                             name: _t.attr('name'),
//                             value: _t.val() || (isArray ? [] : null),
//                         });

//                         filterNames.push(_t.attr('name'));
//                     });

//                     const searchParams = new URLSearchParams(window.location.search);
//                     const newSearchParams = new URLSearchParams();

//                     const spKeys = searchParams.keys();
//                     var spKey = spKeys.next();

//                     while (!spKey.done) {
//                         const spKeyItem = spKey.value;

//                         if (filterNames.indexOf(spKeyItem) == -1) {
//                             const vals = searchParams.getAll(spKeyItem);

//                             for (var i in vals) {
//                                 newSearchParams.append(spKeyItem, vals[i]);
//                             }
//                         }

//                         spKey = spKeys.next();
//                     }

//                     for (var i in filters) {
//                         const val = Array.isArray(filters[i].value) ? filters[i].value : [ filters[i].value ];

//                         for (var v in val) {
//                             newSearchParams.append(filters[i].name, val[v]);
//                         }
//                     }

//                     var newRelativePathQuery = window.location.pathname + '?' + newSearchParams.toString();
//                     history.replaceState(null, '', newRelativePathQuery);
//                 }
//             };

//             setTimeout(function () {
//                 if (window.RatsulaShopFilters) {
//                     const $ = jQuery;
//                     const filters = $('#views-exposed-form-tuotelistaus-block-1');
//                     const letterSizes = [ 'XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL' ];

//                     if (filters.length > 0) {
//                         const sizeFilter = filters.find('select[name="koko[]"]');
//                         const colorFilter = filters.find('select[name="vari[]"]');
//                         const supplierFilter = filters.find('select[name="merkki[]"]');
//                         const exposedForm = filters.find('.views-exposed-form');

//                         const selectedFilters = $('<div class="selected-filters"></div>');
//                         exposedForm.append(selectedFilters);

//                         const filterSelectList = function (filterId, filter, values, defaultText, filterTitle) {
//                             if (values.length == 0) {
//                                 filter.closest('.views-exposed-widget').remove();
//                                 return;
//                             }

//                             const selectedValue = filter.val() || [];
//                             const selectedFilterContainer = $('<div class="selected-filters-filter"></div>').append($('<div class="selected-filters-filter-title"></div>').text(filterTitle + ':'));
//                             const container = $('<div class="view-filter-selection-container"></div>').attr('data-id', filterId);
//                             const selectionsContainer = $('<div class="view-filter-selections-container"></div>');
//                             const selections = $('<div class="view-filter-selections"></div>');
//                             const labelElement = $('<div class="view-filter-selection-label"></div>').text(defaultText);
//                             container.append(labelElement);
//                             container.append($('<div class="vfsl-shadow"></div>'));

//                             selectionsContainer.append(selections);
//                             container.append(selectionsContainer);

//                             selectionsContainer.append($('<span class="link-like-button">Näytä</span>').on('click', function (e) {
//                                 onChangeHandler();
//                                 filter.change();
//                             }));

//                             values.forEach(function (value) {
//                                 const selection = $('<div class="view-filter-selection-value"></div>').attr('data-value', value.value).text(value.name);
//                                 const selectedFilterItem = $('<div class="selected-filter"></div>').attr('data-value', value.value).text(value.name);

//                                 if (selectedValue.indexOf(value.value) >= 0) {
//                                     selection.addClass('selected');
//                                     container.addClass('active');
//                                     selectedFilterContainer.append(selectedFilterItem);
//                                 }

//                                 if (selectedFilterContainer.children().length > 1) {
//                                     selectedFilters.append(selectedFilterContainer);
//                                 }

//                                 const eventOnClick = function (val) {
//                                     const opt = filter.find('option[value="' + val + '"]');
//                                     const sel = selections.find('[data-value="' + val + '"]');
//                                     const sif = selectedFilterContainer.find('[data-value="' + val + '"]');
//                                     const isSelected = opt.prop('selected');

//                                     if (isSelected) {
//                                         sel.removeClass('selected');
//                                         sif.remove();

//                                         if (selectedFilterContainer.children().length < 2) {
//                                             selectedFilterContainer.remove();
//                                             container.addClass('active');
//                                         }
//                                     } else {
//                                         container.addClass('active');
//                                         sel.addClass('selected');
//                                         sif.on('click', function () { eventOnClick($(this).attr('data-value')); });
//                                         selectedFilterContainer.append(sif);

//                                         if (!jQuery.contains(document, selectedFilterContainer)) {
//                                             selectedFilters.append(selectedFilterContainer);
//                                         }
//                                     }

//                                     Drupal.behaviors.ratsula_shop_filters.changed = true;
//                                     opt.prop('selected', !isSelected);

//                                     onChangeHandler();
//                                 };

//                                 selectedFilterItem.on('click', function () { eventOnClick($(this).attr('data-value')); filter.change() });
//                                 selection.on('click', function () { eventOnClick($(this).attr('data-value')); });
//                                 selections.append(selection);
//                             });

//                             filter.parent().append(container);

//                             labelElement.on('click', function () {
//                                 Drupal.behaviors.ratsula_shop_filters.openedFilter = filterId;
//                                 const isOpen = container.hasClass('open');

//                                 if (!isOpen) {
//                                     container.addClass('open');
                                    
//                                     const evntHandler = function (e) {
//                                         if (!$(e.target).closest(selections).length) {
//                                             container.removeClass('open');
//                                             document.removeEventListener('click', evntHandler);

//                                             if (Drupal.behaviors.ratsula_shop_filters.openedFilter == filterId) {
//                                                 Drupal.behaviors.ratsula_shop_filters.openedFilter = null;
//                                             }

//                                             if (Drupal.behaviors.ratsula_shop_filters.changed) {
//                                                 onChangeHandler();
//                                                 filter.change();
//                                             }
//                                         }
//                                     };

//                                     setTimeout(function () {
//                                         document.addEventListener('click', evntHandler);
//                                     }, 0);
//                                 }
//                             });
//                         };

//                         if (window.RatsulaShopFilters.sizes.length > 0) {
//                             filterSelectList('size', sizeFilter, window.RatsulaShopFilters.sizes, 'Koko', 'Koot');
//                         } else {
//                             $('.views-widget-filter-field_koko_value').hide();
//                         }

//                         if (window.RatsulaShopFilters.colors.length > 0) {
//                             filterSelectList('color', colorFilter, window.RatsulaShopFilters.colors, 'Väri', 'Värit');
//                         } else {
//                             $('.views-widget-filter-field_ratsulan_vari_value').hide();
//                         }

//                         if (window.RatsulaShopFilters.suppliers.length > 0) {
//                             filterSelectList('mark', supplierFilter, window.RatsulaShopFilters.suppliers, 'Merkki', 'Merkit');
//                         } else {
//                             $('.views-widget-filter-field_toimittaja_tid').hide();
//                         }

//                         if (Drupal.behaviors.ratsula_shop_filters.openedFilter) {
//                             const container = jQuery('.view-filter-selection-container[data-id="' + Drupal.behaviors.ratsula_shop_filters.openedFilter + '"]');
//                             container.addClass('open');
                            
//                             const evntHandler = function (e) {
//                                 if (!$(e.target).closest('.view-filter-selections').length) {
//                                     container.removeClass('open');
//                                     document.removeEventListener('click', evntHandler);

//                                     if (Drupal.behaviors.ratsula_shop_filters.changed) {
//                                         onChangeHandler();
//                                         container.siblings('select').change();
//                                     }
//                                 }
//                             };

//                             setTimeout(function () {
//                                 document.addEventListener('click', evntHandler);
//                             }, 0);
//                         }

//                     }
//                 }
//             }, 50);
//         });
//     }
// };
